import React, { Component } from "react";
import ReactDOM from "react-dom";
import { useLocation, Redirect } from "react-router-dom";
import { loginUser, logoutUser } from "../lib/identityActions";
import netlifyIdentity from "netlify-identity-widget";
import { dbExists, dbUpsertMany } from "./utils/db-api";
import { authStrava, syncStrava } from "./utils/strava-api";
import { hashStringToInt } from "./utils/hash";
import loadingAnimation from "../images/loadingAnimation.svg";
import { Button } from "@material-ui/core";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

class ThanksText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: <img src={loadingAnimation} alt="loading" />,
      subtext: "",
      authStravaComplete: false,
    };
  }

  async storeUser(antwonUser) {
    const userData = [
      {
        collection: "Users",
        id: hashStringToInt(antwonUser["id"]),
        data: antwonUser,
      },
    ];
    await dbUpsertMany(userData)
      .then(async (response) => {
        return await response;
      })
      .catch((error) => {
        console.log("API error", error);
        this.setState({
          text: "Strava account successfully linked!",
          toRedirect: false,
        });
      });
  }

  async syncStravaActivities(antwonUser, stravaUser) {
    await syncStrava(hashStringToInt(antwonUser["id"]), stravaUser)
      .then((response) => {
        if (response["status"] === "complete") {
          this.setState({
            text: "Strava account successfully linked!",
            subtext: "Click below to return home",
            toRedirect: true,
          });
          return response;
        } else {
          this.setState({
            text: "There was an error syncing your strava",
            toRedirect: false,
          });
        }
      })
      .catch((error) => {
        console.log("API error", error);
        this.setState({
          text: "There was an error syncing your strava data",
          toRedirect: false,
        });
      });
  }

  async sendAuthToStrava(antwonUser) {
    const stravaData = {
      code: this.props.code,
      antwonUserID: hashStringToInt(antwonUser["id"]),
    };
    return await authStrava(stravaData)
      .then(async (response) => {
        if (!response.ok) {
          this.setState({
            text: "There was an error authenticating your strava",
            authStravaComplete: false,
          });
        } else {
          const userData = await response.json();
          this.setState({
            authStravaComplete: true,
          });
          return userData["data"];
        }
      })
      .catch((error) => {
        console.log("API error", error);
        this.setState({
          text: "There was an error submitting your profile",
          toRedirect: false,
        });
      });
  }

  checkUserAuth(antwonUser) {
    if (antwonUser) {
      this.setState({ antwonUser: antwonUser });
    } else {
      loginUser();
      const antwonUser = localStorage.getItem("currentAntwonUser");
      if (!antwonUser) {
        netlifyIdentity.open();
      } else {
        this.setState({ antwonUser: antwonUser });
      }
      netlifyIdentity.on("login", (user) =>
        this.setState({ user }, loginUser())
      );
      netlifyIdentity.on("logout", (user) =>
        this.setState({ user: null }, logoutUser())
      );
    }
  }

  async storeUserInfo(antwonUser) {
    await this.storeUser(antwonUser);
    this.setState({ toRedirect: false });
    const stravaUser = await this.sendAuthToStrava(antwonUser);
    if (this.state.authStravaComplete) {
      await this.syncStravaActivities(antwonUser, stravaUser);
    }
  }

  componentDidMount() {
    const antwonUser = JSON.parse(localStorage.getItem("currentAntwonUser"));
    this.checkUserAuth(antwonUser);
    if (antwonUser == null) {
      this.setState({ toRedirect: true });
    } else {
      this.storeUserInfo(antwonUser);
    }
  }

  render() {
    let returnButton = <div></div>;
    if (this.state.toRedirect) {
      returnButton = (
        <Button
          href={"https://antwon.fit"}
          variant="contained"
          color="secondary"
        >
          Home
        </Button>
      );
    }
    return (
      <div className="App">
        <header className="App-header">
          <p>{this.state.text}</p>
          <p style={{ fontSize: 20 }}>{this.state.subtext}</p>
          {returnButton}
        </header>
      </div>
    );
  }
}

function RedirectPage() {
  let query = useQuery();
  return <ThanksText code={query.get("code")} />;
}

export default RedirectPage;
