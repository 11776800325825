import fetch from "node-fetch";

export function dbCreate(data) {
  return fetch(`/.netlify/functions/db-create`, {
    body: JSON.stringify(data),
    method: "POST",
  }).then((response) => {
    return response.json();
  });
}

export function dbUpsertMany(data) {
  return fetch(`/.netlify/functions/db-upsert-many`, {
    body: JSON.stringify(data),
    method: "POST",
  }).then((response) => {
    return response.json();
  });
}

export function dbRead(search) {
  return fetch(`/.netlify/functions/db-read/${search}`).then((response) => {
    return response;
  });
}

export function dbDelete(search) {
  return fetch(`/.netlify/functions/db-delete/${search}`).then((response) => {
    return response;
  });
}

export function dbReadIndex(search) {
  return fetch(`/.netlify/functions/db-read-index/${search}`).then(
    (response) => {
      return response;
    }
  );
}

export function dbExists(search) {
  return fetch(`/.netlify/functions/db-exists/${search}`).then((response) => {
    return response;
  });
}

export function dbUpdate(data) {
  return fetch("/.netlify/functions/db-create", {
    body: JSON.stringify(data),
    method: "POST",
  }).then((response) => {
    return response;
  });
}
