export function hashStringToInt(inputString) {
  let hash = 0,
    i,
    chr;
  for (i = 0; i < inputString.length; i++) {
    chr = inputString.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  if (hash < 0) {
    hash = hash * -10;
  }
  return hash;
}
