import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Button } from "@material-ui/core";
import netlifyIdentity from "netlify-identity-widget";
import { loginUser, logoutUser } from "../lib/identityActions";
import LoginPage from "./LoginPage";
import AccountPage from "./AccountPage";

class MainPage extends Component {
  constructor() {
    super();
    this.state = { antwonUser: null };
  }

  componentDidMount() {
    const antwonUser = JSON.parse(localStorage.getItem("currentAntwonUser"));
    if (antwonUser != null) {
      this.setState({ antwonUser: antwonUser });
    } else {
      loginUser();
    }
    netlifyIdentity.on("login", (antwonUser) =>
      this.setState({ antwonUser: antwonUser }, loginUser())
    );
    netlifyIdentity.on("logout", (antwonUser) =>
      this.setState({ antwonUser: null }, logoutUser())
    );
  }

  render() {
    let page;
    if (this.state.antwonUser == null) {
      page = <LoginPage />;
    } else {
      page = <AccountPage />;
    }
    return <div className="App">{page}</div>;
  }
}

export default MainPage;
