import React, { Component } from "react";
import { Button } from "@material-ui/core";
import netlifyIdentity from "netlify-identity-widget";
import { isMobile } from "react-device-detect";

class LoginPage extends Component {
  constructor() {
    super();
    this.handleLogIn = this.handleLogIn.bind(this);
  }
  handleLogIn() {
    netlifyIdentity.open();
  }
  render() {
    return (
      <div>
        <div
          style={{
            fontSize: 20,
            textAlign: "center",
            float: "center",
            padding: 20,
          }}
        >
          {"A  N  T  W  O  N"}
        </div>
        <div className="App-header">
          <div style={isMobile ? { fontSize: "50px" } : { fontSize: "80px" }}>
            {"Sign up below"}
          </div>
          <br />
          <Button
            variant="contained"
            color="secondary"
            onClick={this.handleLogIn}
          >
            Log In
          </Button>
        </div>
      </div>
    );
  }
}
export default LoginPage;
