import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { dbExists, dbUpsertMany } from "./utils/db-api";
import { hashStringToInt } from "./utils/hash";
import "react-datepicker/dist/react-datepicker.css";
import { syncStrava } from "./utils/strava-api";
import ManualForm from "./ManualForm";
import ActivitiesList from "./Activities";
import loadingAnimation from "../images/loadingAnimation.svg";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import MenuIcon from "@material-ui/icons/Menu";
// const redirect_uri = "http://localhost:3000";
const redirect_uri = "https://antwon.fit";

class AccountPage extends Component {
  constructor() {
    const antwonUser = JSON.parse(localStorage.getItem("currentAntwonUser"));
    const first_name = antwonUser["full_name"].split(" ")[0];
    super();
    this.state = {
      antwonUser: null,
      stravaLinked: null,
      formOpen: false,
      burgerOpen: false,
      text: `Welcome ${first_name}!`,
    };
  }

  async checkStravaLinked(antwonUser) {
    const search = "StravaUsers&" + hashStringToInt(antwonUser["id"]);
    const response = await dbExists(search);
    const stravaLinked = await response.json();
    this.setState({ stravaLinked: stravaLinked });
  }

  async syncStravaActivities() {
    const antwonUser = JSON.parse(localStorage.getItem("currentAntwonUser"));
    this.setState({
      text: <img src={loadingAnimation} alt="loading" />,
    });
    await syncStrava(hashStringToInt(antwonUser["id"]), null)
      .then((response) => {
        if (response["status"] === "success") {
          this.setState({
            text: "Strava synced",
          });
        } else {
          console.log("API error", response);
          this.setState({
            text: "There was an error syncing your strava",
          });
        }
      })
      .catch((error) => {
        console.log("API error", error);
        this.setState({
          text: "There was an error syncing your strava data",
        });
      });
  }

  onButtonClick(e) {
    this.setState({ formOpen: true });
  }

  onFormClose(e) {
    this.setState({ formOpen: false });
  }

  async storeUser() {
    const antwonUser = JSON.parse(localStorage.getItem("currentAntwonUser"));
    const userData = [
      {
        collection: "Users",
        id: hashStringToInt(antwonUser["id"]),
        data: antwonUser,
      },
    ];
    await dbUpsertMany(userData)
      .then(async (response) => {
        return await response;
      })
      .catch((error) => {
        console.log("API error", error);
        this.setState({
          text: "There was an error submitting your profile",
          toRedirect: false,
        });
      });
  }

  componentDidMount() {
    const antwonUser = JSON.parse(localStorage.getItem("currentAntwonUser"));
    this.setState({
      antwonUser: antwonUser,
      stravaLinked: null,
    });
    this.forceUpdate();
    this.checkStravaLinked(antwonUser);
    this.storeUser();
  }

  openBurger() {
    this.setState({ burgerOpen: true });
  }

  onBurgerClose() {
    this.setState({ burgerOpen: false });
  }

  render() {
    const antwonUser = JSON.parse(localStorage.getItem("currentAntwonUser"));
    let stravaSection;
    if (this.state.stravaLinked === null) {
      return <img src={loadingAnimation} alt="loading" />;
    }
    if (this.state.stravaLinked) {
      stravaSection = (
        <div>
          <p style={{ fontSize: 20 }}>Strava Linked</p>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.syncStravaActivities.bind(this)}
          >
            Resync Strava
          </Button>
        </div>
      );
    } else {
      stravaSection = (
        <div>
          <p style={{ fontSize: 20 }}>Strava Not Linked</p>
          <Button
            href={
              "http://www.strava.com/oauth/authorize?client_id=59463&response_type=code&redirect_uri=" +
              redirect_uri +
              "/exchange_token&approval_prompt=force&scope=activity:read_all"
            }
            variant="contained"
            color="secondary"
          >
            Click here to connect Strava
          </Button>
        </div>
      );
    }
    return (
      <div className={"App"}>
        <div
          style={{
            fontSize: 20,
            textAlign: "center",
            float: "center",
            padding: 20,
          }}
        >
          {"A  N  T  W  O  N"}
        </div>
        <MenuRoundedIcon
          onClick={this.openBurger.bind(this)}
          style={{ fontSize: "40px", position: "absolute", top: 15, right: 20 }}
        />

        <div className="App-header">
          <h1>{this.state.text}</h1>
          {stravaSection}
          <br/>
          <Button
              variant="contained"
              color="secondary"
              href={"https://datastudio.google.com/u/0/reporting/198e5eab-91e0-465e-90d9-2e0a5c989921/page/Zp6wB"}
          >
            Leaderboard + Info
          </Button>
          <br />
          <Button
            variant="contained"
            color="secondary"
            onClick={this.onButtonClick.bind(this)}
          >
            Add Activity
          </Button>
          {this.state.formOpen ? (
            <ManualForm onFormClose={this.onFormClose.bind(this)} />
          ) : (
            ""
          )}
          {this.state.burgerOpen ? (
            <ActivitiesList onBurgerClose={this.onBurgerClose.bind(this)} />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default AccountPage;
