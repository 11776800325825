import fetch from "node-fetch";

export function authStrava(authCode) {
  return fetch(
    `/.netlify/functions/auth-strava/${authCode["antwonUserID"]}&${authCode["code"]}`
  ).then((response) => {
    return response;
  });
}

export function syncStrava(antwonUserIDHash, stravaUser) {
  return fetch(`/.netlify/functions/sync-strava`, {
    body: JSON.stringify({
      antwonUserIDHash: antwonUserIDHash,
      stravaUser: stravaUser,
    }),
    method: "POST",
  }).then((response) => {
    return response.json();
  });
}
