import React, { Component } from "react";
import MenuOpenRoundedIcon from "@material-ui/icons/MenuOpenRounded";
import styled from "styled-components";
import { isBrowser, isMobile } from "react-device-detect";
import { List } from "semantic-ui-react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import StravaIcon from "../images/stravaIcon.png";
import AntwonIcon from "../images/antwonIcon.icon";
import { hashStringToInt } from "./utils/hash";
import { dbExists, dbReadIndex, dbDelete } from "./utils/db-api";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Edit } from "@material-ui/icons";
import ConfirmDialog from "./confirmation";

const ActivityDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  transition: 1s all;
  ${isMobile &&
  `
           position: absolute;
         height: 100%;
         width: 100%;
        top:0;
        left:0;
    `}
  ${isBrowser &&
  `
         position: absolute;
         height: 80%;
         width: 100%;
         top: 100px;
         left: 0%;
    `}
  background: #eeeeee;
`;

const useStyles = makeStyles({
  table: {
    minWidth: 1,
    fontSize: 5,
    padding: 0,
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 14,
    padding: 0,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

const ActivitiesTable = (props) => {
  const classes = useStyles();
  const activities = props.activities;

  return (
    <TableContainer
      component={Paper}
      style={{ width: "95%", marginRight: 100, marginLeft: "2.5%" }}
    >
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Date</StyledTableCell>
            <StyledTableCell align="center">Type</StyledTableCell>
            <StyledTableCell align="center">Distance</StyledTableCell>
            <StyledTableCell align="center">Duration</StyledTableCell>
            <StyledTableCell align="center"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.map((row, i) => (
            <StyledTableRow key={row.data.dateValue}>
              <StyledTableCell component="th" scope="row">
                {new Date(
                  Date.parse(row.data.dateValue)
                ).toLocaleString("en-UK", {
                  month: "short",
                  day: "numeric",
                  hour12: false,
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </StyledTableCell>
              <StyledTableCell>
                <img
                  src={row.data.source === "strava" ? StravaIcon : AntwonIcon}
                  style={{ height: 30 }}
                />
                <br />
                {row.data.activityType}
              </StyledTableCell>
              <StyledTableCell>
                {parseFloat(row.data.distance).toFixed(2)}
              </StyledTableCell>
              <StyledTableCell>
                {row.data.elapsedTime.slice(0, 5)}
              </StyledTableCell>
              <StyledTableCell>
                {row.data.source !== "XXXXstrava" ? (
                  <div>
                    <DeleteIcon
                      onClick={(e) => props.onDeleteClick(i)}
                      style={{ color: "red" }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default class ActivitiesList extends Component {
  constructor() {
    super();
    this.state = {
      activities: [],
      confirmOpen: false,
    };
  }

  onDeleteClick(i) {
    this.setState({ toDelete: i });
    this.setState({ confirmOpen: true });
  }

  async deleteActivity() {
    const activities = this.state.activities;
    const id = activities[this.state.toDelete]["ref"]["@ref"]["id"];
    activities.splice(this.state.toDelete, 1);
    this.setState({ activities: activities });
    await dbDelete(`Activities&${id}`);
  }

  async fetchActivies() {
    const antwonUser = JSON.parse(localStorage.getItem("currentAntwonUser"));
    const search = "activities_table&" + hashStringToInt(antwonUser["id"]);
    const response = await dbReadIndex(search);
    let activities = await response.json();
    activities = activities["data"].filter(function (el) {
      return Date.parse(el.data.dateValue) >= 1652050800000;
    });
    activities.sort(function (a, b) {
      var x = a.data.dateValue.toLowerCase();
      var y = b.data.dateValue.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    this.setState({ activities: activities });
  }

  componentDidMount() {
    this.fetchActivies();
  }

  setOpenConfirmation(state) {
    this.setState({ confirmOpen: state });
  }

  render() {
    let closingIcon;
    if (isMobile) {
      closingIcon = (
        <MenuOpenRoundedIcon
          onClick={this.props.onBurgerClose}
          style={{
            color: "black",
            fontSize: "40px",
            alignSelf: "flex-end",
            marginRight: 15,
            marginTop: "15px",
          }}
        />
      );
    } else {
      closingIcon = (
        <CloseIcon
          style={{
            color: "black",
            fontSize: "40px",
            alignSelf: "flex-end",
            marginRight: 15,
            marginTop: "15px",
          }}
          onClick={this.props.onBurgerClose}
        />
      );
    }
    return (
      <div>
        <ConfirmDialog
          onConfirm={this.deleteActivity.bind(this)}
          setOpen={this.setOpenConfirmation.bind(this)}
          open={this.state.confirmOpen}
          title={"Are you sure you want to delete this activity?"}
        />
        <ActivityDiv>
          {closingIcon}
          <ActivitiesTable
            onDeleteClick={this.onDeleteClick.bind(this)}
            activities={this.state.activities}
          />
        </ActivityDiv>
      </div>
    );
  }
}
