import React, { Component } from "react";
import "./App.css";
import { Route } from "react-router-dom";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import MainPage from "./components/MainPage";
import RedirectPage from "./components/RedirectPage";

class App extends Component {
  render() {
    return (
      <Router>
        <Route exact path="/exchange_token" component={RedirectPage} />
        <Route path="/" component={MainPage} />
      </Router>
    );
  }
}

export default App;
