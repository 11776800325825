import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Button, TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import styled from "styled-components";
import { isBrowser, isMobile } from "react-device-detect";
import CloseIcon from "@material-ui/icons/Close";
import { dbCreate } from "./utils/db-api";
import { hashStringToInt } from "./utils/hash";

const FormDiv = styled.form`
  display: flex;

  transition: 1s all;
  ${isMobile &&
  `
           position: fixed;
         height: 100%;
         width: 100%;
        top:0;
        left:0;
    `}
  ${isBrowser &&
  `
         position: absolute;
         height: 80%;
         width: 45%;
         top: 100px;
         left: 30%;
    `}
  background: #eeeeee;
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(10),
    marginTop: theme.spacing(10),
    width: isBrowser ? "35%" : "60%",
    alignContent: "center",
    position: "fixed",
  },
  selectEmpty: {
    marginTop: theme.spacing(10),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function ManualForm(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    activityType: "",
    dateValue: "",
    hoursValue: "00h",
    minutesValue: "00m",
    secondsValue: "00s",
    distance: "0",
    imageLink: "",
    submitted: false,
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const storeActivity = async () => {
    const antwonUser = JSON.parse(localStorage.getItem("currentAntwonUser"));
    const data = {
      userID: antwonUser["id"],
      userIDHash: hashStringToInt(antwonUser["id"]),
      source: "manual",
      activityType: values['activityType'],
      dateValue: values["dateValue"].replace("T", " "),
      elapsedTime: values['hoursValue'].slice(0, 2) + ":" + values['minutesValue'].slice(0, 2) + ":" + values['secondsValue'].slice(0, 2),
      distance: values['distance'],
      imageLink: values['imageLink']
    };
    const formData = {
      collection: "Activities",
      data: data,
    };
    dbCreate(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("API error", error);
        this.setState({
          text: "There was an error submitting your actvity",
          toRedirect: false,
        });
      });
  };

  const onFormSubmit = async (event) => {
    await storeActivity();
    event.preventDefault();
    setValues({
      ...values,
      ["submitted"]: true,
    });
  };

  const generateMenuItems = (end, suffix) => {
    let i = 0;
    let number;
    let value;
    let items = [];
    while(i < end+1){
      if (i < 10) {
        number = "0" + i
      } else {
        number = i
      }
      value = number + suffix;
      items.push(<MenuItem value={value}>{number}{suffix}</MenuItem>);
      i++
    }
    return items
  }

  return (
    <FormDiv onSubmit={onFormSubmit}>
      <CloseIcon
          style={{ color: "black", marginLeft: "auto" }}
          onClick={props.onFormClose}
      />
      <div className={classes.formControl}>
      <FormControl style={{width:"100%"}}>
        <InputLabel id="activity-type-label">Type</InputLabel>
        <Select
          labelId="activity-type-label"
          id="activity-type"
          value={values.activityType}
          name={"activityType"}
          required
          onChange={handleChange}
          disabled={values.submitted}
          label="Activity Type"
        >
          <MenuItem value={"Cycle"}>Cycle</MenuItem>
          <MenuItem value={"Row"}>Row</MenuItem>
          <MenuItem value={"Run"}>Run</MenuItem>
          <MenuItem value={"Sports"}>Sports</MenuItem>
          <MenuItem value={"Strength"}>Strength</MenuItem>
          <MenuItem value={"Swim"}>Swim</MenuItem>
          <MenuItem value={"Walk"}>Walk</MenuItem>
          <MenuItem value={"Workout"}>Workout</MenuItem>
          <MenuItem value={"Yoga"}>Yoga</MenuItem>
        </Select>
      </FormControl>
        <br/><br/>
        <FormControl style={{width:"100%"}}>
        <TextField
            id="date"
            value={values.dateValue}
            name={"dateValue"}
            required
            onChange={handleChange}
            disabled={values.submitted}
            label="Start Date & Time"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
        />
        </FormControl>

        <br/><br/>
        <FormHelperText>
          Duration
        </FormHelperText>
      <FormControl style={{width: "33%"}}>
          <Select
              labelId="hours-label"
              id="hours-label"
              value={values.hoursValue}
              name={"hoursValue"}
              required
              onChange={handleChange}
              disabled={values.submitted}
              label="hoursValue"
          >
            {generateMenuItems(24, 'h').map((item) => (
                item
                ))
            }
          </Select>
      </FormControl>
        <FormControl style={{width: "33%"}}>
          <Select
              labelId="minutes-label"
              id="minutes-type"
              value={values.minutesValue}
              name={"minutesValue"}
              required
              onChange={handleChange}
              disabled={values.submitted}
              label="minutesValue"

          >
            {generateMenuItems(60, 'm').map((item) => (
                item
            ))
            }
          </Select>
        </FormControl>
        <FormControl style={{width: "33%"}}>
          <Select
                  labelId="seconds-label"
                  id="seconds-type"
                  value={values.secondsValue}
                  name={"secondsValue"}
                  required
                  onChange={handleChange}
                  disabled={values.submitted}
                  label="secondsValue"
          >
            {generateMenuItems(60, 's').map((item) => (
                item
            ))
            }
          </Select>
      </FormControl>
        <FormHelperText>
          Enter duration in the form: hours, minutes, seconds
        </FormHelperText>
        <br/>
        <FormControl style={{width:"100%"}}>
        <TextField
          id="distance"
          label="Distance"
          type={"number"}
          value={values.distance}
          name={"distance"}
          disabled={values.submitted}
          required
          onChange={handleChange}
          inputProps={{
            step: 0.1,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">km</InputAdornment>,
          }}
        />
        </FormControl>
        <br/><br/>
        <FormControl style={{width:"100%"}}>
        <TextField
          id="imageLink"
          label="Evidence Link"
          value={values.imageLink}
          name={"imageLink"}
          disabled={values.submitted}
          required
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormHelperText>
          Please Provide a link to an image for evidence e.g. Google Photos
        </FormHelperText>
        </FormControl>

        <br /><br />
        <FormControl style={{width:"100%"}}>
        <Button
          disabled={values.submitted}
          variant="contained"
          color="primary"
          label="Submit"
          type="submit"
        >
          {values.submitted ? "activity submitted!" : "Submit"}
        </Button>
      </FormControl>
      </div>
    </FormDiv>
  );
}
